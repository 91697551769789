"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(require("ramda"));

var renderGroup = function renderGroup() {
  return R.identity;
};

var _default = renderGroup;
exports.default = _default;